
import { defineComponent, PropType, nextTick } from "vue";
import { useRouter } from "vue-router";
export interface RecommnedList {
  link: object;
  id: number;
  title: string;
}

export default defineComponent({
  name: "Recommend",
  props: {
    list: {
      type: Array as PropType<RecommnedList[]>,
      default: [],
    },
    title: String,
  },
  setup() {
    const router = useRouter();

    function viewRecommend(link: object) {
      router.push(link);
    }
    return {
      viewRecommend,
    };
  },
});
